// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file bff/v1/tender_service.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { EditableTender, Operators, Tender, TenderStatus } from "@enechain/etender-proto/proto/etender/tender/v1/model_pb.js";
import { DataSet, DataSetForOrder } from "./data_set_model_pb.js";
import { OrderedTender, TenderTermsIDs } from "./tender_model_pb.js";

/**
 * *
 * 入札案件を取得するリクエスト
 *
 * @generated from message bff.v1.GetForTraderRequest
 */
export const GetForTraderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetForTraderRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * 入札案件を取得するレスポンス
 *
 * @generated from message bff.v1.GetForTraderResponse
 */
export const GetForTraderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetForTraderResponse",
  () => [
    { no: 1, name: "tender", kind: "message", T: Tender },
    { no: 2, name: "data_set", kind: "message", T: DataSet },
  ],
);

/**
 * *
 * 入札案件を取得するリクエスト
 *
 * @generated from message bff.v1.GetForOperatorRequest
 */
export const GetForOperatorRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetForOperatorRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * 入札案件を取得するレスポンス
 *
 * @generated from message bff.v1.GetForOperatorResponse
 */
export const GetForOperatorResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetForOperatorResponse",
  () => [
    { no: 1, name: "tender", kind: "message", T: EditableTender },
    { no: 2, name: "data_set", kind: "message", T: DataSet },
  ],
);

/**
 * *
 * トレーダー画面の入札案件一覧を取得するリクエスト
 *
 * @generated from message bff.v1.ListForTraderRequest
 */
export const ListForTraderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListForTraderRequest",
  () => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * トレーダー画面の入札案件一覧を取得するレスポンス
 *
 * @generated from message bff.v1.ListForTraderResponse
 */
export const ListForTraderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListForTraderResponse",
  () => [
    { no: 1, name: "tenders", kind: "message", T: Tender, repeated: true },
    { no: 2, name: "data_set", kind: "message", T: DataSet },
    { no: 3, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * *
 * オペレーター画面の入札案件一覧を取得するリクエスト
 *
 * @generated from message bff.v1.ListForOperatorRequest
 */
export const ListForOperatorRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListForOperatorRequest",
  () => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(TenderStatus) },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * オペレーター画面の入札案件一覧を取得するレスポンス
 *
 * @generated from message bff.v1.ListForOperatorResponse
 */
export const ListForOperatorResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListForOperatorResponse",
  () => [
    { no: 1, name: "tenders", kind: "message", T: EditableTender, repeated: true },
    { no: 2, name: "data_set", kind: "message", T: DataSet },
    { no: 3, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * 入札案件のオペレーターを追加するリクエスト
 *
 * @generated from message bff.v1.UpdateOperatorsRequest
 */
export const UpdateOperatorsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.UpdateOperatorsRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "operators", kind: "message", T: Operators },
  ],
);

/**
 * 入札案件のオペレーターを追加するレスポンス
 *
 * @generated from message bff.v1.UpdateOperatorsResponse
 */
export const UpdateOperatorsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.UpdateOperatorsResponse",
  [],
);

/**
 * 注文済みの入札案件の一覧を取得するリクエスト
 *
 * @generated from message bff.v1.ListOrderedRequest
 */
export const ListOrderedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListOrderedRequest",
  () => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * 注文済みの入札案件の一覧を取得するレスポンス
 *
 * @generated from message bff.v1.ListOrderedResponse
 */
export const ListOrderedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListOrderedResponse",
  () => [
    { no: 1, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "data_set", kind: "message", T: DataSet },
    { no: 4, name: "data_set_for_order", kind: "message", T: DataSetForOrder },
    { no: 5, name: "tenders", kind: "message", T: OrderedTender, repeated: true },
    { no: 6, name: "tender_terms_id_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: TenderTermsIDs} },
    { no: 7, name: "last_applied_at_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Timestamp} },
  ],
);

