import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'

import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react'

import {
  AccordionIcon,
  DateFormat,
  Divider,
  formatTimestamp,
} from '@enechain/common-ui'
import {
  BorderRadius,
  SemanticColors,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'

import DealTypeTag from '~/components/order/DealTypeTag'
import HistoryTable from '~/pages/history-detail/components/OrderHistorySection/HistoryTable'
import TenderTermsTable from '~/pages/history-detail/components/OrderHistorySection/TenderTermsTable'
import { useListMyDeals } from '~/pages/history-detail/components/OrderHistorySection/hooks/useListMyDeals'

const useSwingOption = (tenderId: string): boolean => {
  const { data } = useSuspenseQuery(
    listByTenderID,
    {
      tenderId,
    },
    {
      select: (response): boolean => {
        const tender = response.dataSetForOrder?.tenders[tenderId]
        return tender?.withSwingOption ?? false
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
}

const DealInformation: React.FC<Props> = ({ tenderId }) => {
  const withSwingOption = useSwingOption(tenderId)
  const myDeals = useListMyDeals({ tenderId })

  const sleeveDeals = myDeals.deals.filter((deal) => deal.isCreditSleeveDeal)
  const ownTenderDeals = myDeals.deals.filter((deal) => deal.isTenderOwnerDeal)

  if (sleeveDeals.length === 0 && ownTenderDeals.length === 0) {
    return null
  }

  const uniqueSleeveDealsTenderTermsIds = Array.from(
    new Set(sleeveDeals.map((deal) => deal.tenderTermsId)),
  )
  const uniqueOwnTenderDealsTenderTermsIds = Array.from(
    new Set(ownTenderDeals.map((deal) => deal.tenderTermsId)),
  )

  return (
    <Flex
      flexDirection="column"
      gap={Spacing['4']}
      padding={Spacing['4']}
      borderWidth="1px"
      borderColor={SemanticColors.Border.lowEmphasis}
      borderRadius={BorderRadius.radiiLg}
    >
      <AccordionItem
        key="deal"
        flexDirection="column"
        gap={Spacing['4']}
        display="flex"
        borderWidth={0}
        _last={{ borderBottomWidth: 0 }}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Flex flexDirection="column" gap={Spacing['2']}>
            <Flex alignItems="center" flexDirection="row" gap={Spacing['3']}>
              <DealTypeTag
                type={
                  uniqueSleeveDealsTenderTermsIds.length > 0
                    ? 'sleever'
                    : 'owner'
                }
              />
            </Flex>
            {myDeals.publishedAt != null && (
              <Text
                color={SemanticColors.Text.lowEmphasis}
                {...Typography.textMd}
              >
                {formatTimestamp(
                  myDeals.publishedAt,
                  DateFormat.DATE_TIME_MINUTE_SLASH,
                )}
              </Text>
            )}
          </Flex>
          <Flex gap={Spacing['4']}>
            <AccordionButton
              as={IconButton}
              width="40px"
              height="40px"
              marginRight={Spacing['-1']}
              padding={Spacing['2']}
              backgroundColor={SemanticColors.Surface.primary}
            >
              <AccordionIcon closeIcon="up" openIcon="down" size="40px" />
            </AccordionButton>
          </Flex>
        </Flex>
        <AccordionPanel
          flexDirection="column"
          gap={Spacing['4']}
          display="flex"
          padding={Spacing['0']}
        >
          <Divider />
          {uniqueSleeveDealsTenderTermsIds.map((tenderTermsId) => (
            <TenderTermsTable
              key={tenderTermsId}
              tenderId={tenderId}
              tenderTermsId={tenderTermsId}
              withSwingOption={withSwingOption}
            >
              <HistoryTable
                displayType="sleever"
                tenderId={tenderId}
                tenderTermsId={tenderTermsId}
                withSwingOption={withSwingOption}
              />
            </TenderTermsTable>
          ))}
          {uniqueOwnTenderDealsTenderTermsIds.map((tenderTermsId) => (
            <TenderTermsTable
              key={tenderTermsId}
              tenderId={tenderId}
              tenderTermsId={tenderTermsId}
              withSwingOption={withSwingOption}
            >
              <HistoryTable
                displayType="tender_owner"
                tenderId={tenderId}
                tenderTermsId={tenderTermsId}
                withSwingOption={withSwingOption}
              />
            </TenderTermsTable>
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Flex>
  )
}
export default React.memo(DealInformation)
