import * as React from 'react'
import { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Await, useLoaderData, useNavigate } from 'react-router-dom'

import { Flex, useDisclosure } from '@chakra-ui/react'

import { ErrorLayout } from '@enechain/common-ui'
import {
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { SideMenuLayoutBreadcrumbItem } from '@enechain/ecloud-designsystem/dist/src/components/SideMenuLayout/components/SideMenuLayoutBreadcrumb'
import { Status } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

import { usePreviousPageUrl } from '~/hooks/usePreviousPageUrl'
import AdditionalFieldsSection from '~/pages/order/components/AdditionalFieldsSection'
import EditingOrderModal from '~/pages/order/components/EditingOrderModal'
import OrderFooter from '~/pages/order/components/Footer'
import OrderHeader from '~/pages/order/components/Header'
import OrderSection from '~/pages/order/components/OrderSection'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'
import { OrderLoaderData, OrderLoaderResponse } from '~/pages/order/loader'
import { routePaths } from '~/routes/routePaths'

type OrderPageData = {
  orderType: 'order' | 'edit'
}

const useOrderPageData = (orderId: string): OrderPageData => {
  const { data } = useEditingOrder<OrderPageData>(orderId, (response) => {
    const isDraft = response.editingOrder?.status === Status.DRAFT

    return {
      orderType: isDraft ? 'order' : 'edit',
    }
  })
  return data
}

const Layout: React.FC<{ data: OrderLoaderData }> = ({ data }) => {
  const { orderId, tenderId, isEditing } = data
  const navigate = useNavigate()
  const { t } = useTranslation('trader', {
    keyPrefix: 'order',
  })

  const { orderType } = useOrderPageData(orderId)

  const breadcrumbItems = useMemo<SideMenuLayoutBreadcrumbItem[]>(() => {
    switch (orderType) {
      case 'order':
        return [{ label: t('page_title'), bold: true }]
      case 'edit':
        return [
          {
            label: t('breadcrumb.eTender'),
            onClick: (): void => navigate(routePaths.tender()),
          },
          {
            label: t('breadcrumb.history'),
            onClick: (): void => navigate(routePaths.history()),
          },
          {
            label: t('breadcrumb.history_detail'),
            onClick: (): void =>
              navigate(
                routePaths.historyDetail({
                  params: { tenderId },
                }),
              ),
          },
          { label: t('page_title'), bold: true },
        ]
    }
  }, [navigate, orderType, t, tenderId])

  const previousPageUrl = usePreviousPageUrl()
  const isEditingOrderModalOpen = useMemo(() => {
    // 確認画面から戻ってきたときにはModalを出さない
    const orderConfirmPattern = /^\/tender\/[^/]+\/order\/[^/]+\/confirm$/
    const fromConfirm = orderConfirmPattern.test(previousPageUrl)
    return isEditing && !fromConfirm
  }, [isEditing, previousPageUrl])

  const disclosure = useDisclosure({ defaultIsOpen: isEditingOrderModalOpen })

  // 注文の編集破棄時にユーザーが入力した状態をリセットするためにkeyを変更する
  const [keySuffix, setKeySuffix] = useState(0)
  const onDiscarded = (): void => {
    setKeySuffix(Date.now())
  }
  const pageKey = `OrderPage-${orderId}-${keySuffix}`

  return (
    <SideMenuLayoutContentLayout
      header={<SideMenuLayoutBreadcrumb items={breadcrumbItems} />}
    >
      <>
        <OrderHeader orderId={orderId} />
        <Flex key={pageKey} flexDirection="column" width="100%" height="100%">
          <Flex
            flexDirection="column"
            flexGrow={1}
            gap={Spacing['8']}
            overflow="scroll"
            width="100%"
            padding={Spacing['6']}
          >
            <OrderSection orderId={orderId} tenderId={tenderId} />
            <AdditionalFieldsSection orderId={orderId} />
          </Flex>
          <OrderFooter orderId={orderId} tenderId={tenderId} />
        </Flex>
        {isEditingOrderModalOpen && (
          <EditingOrderModal
            disclosure={disclosure}
            onDiscarded={onDiscarded}
            orderId={orderId}
          />
        )}
      </>
    </SideMenuLayoutContentLayout>
  )
}

const OrderPage: React.FC = () => {
  const { data } = useLoaderData() as OrderLoaderResponse

  return (
    <Await errorElement={<ErrorLayout />} resolve={data}>
      {(data: OrderLoaderData): JSX.Element => <Layout data={data} />}
    </Await>
  )
}

export default OrderPage
