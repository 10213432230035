import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'

import { HistoryColumnKey } from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/HistoryColumnStyle'

type HistoryTableHeaderRowProps = {
  isSleeverOrTenderOwner?: boolean
  withSwingOption: boolean
}

const HistoryTableHeaderRow: React.FC<HistoryTableHeaderRowProps> = ({
  isSleeverOrTenderOwner,
  withSwingOption,
}: HistoryTableHeaderRowProps) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'history_detail.order_history_section.bid_history_table.header',
  })

  return (
    <FlexTableRow>
      <FlexTableCell<HistoryColumnKey>
        columnKey="bid_or_offer"
        contents={{ text: t('bid_or_offer') }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="bidId"
        contents={{
          text: t('bid_id'),
        }}
      />
      {isSleeverOrTenderOwner === false && (
        <>
          <FlexTableCell<HistoryColumnKey>
            columnKey="unitPrice"
            contents={{
              text: t(withSwingOption ? 'unit_price_month' : 'unit_price'),
            }}
          />
          <FlexTableCell<HistoryColumnKey>
            columnKey="volume"
            contents={{ text: t('volume') }}
          />
        </>
      )}
      <FlexTableCell<HistoryColumnKey>
        columnKey="dealStatus"
        contents={{ text: t('deal_status') }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="dealOrganizationName"
        contents={{ text: t('deal_organization_name') }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="dealUnitPrice"
        contents={{ text: t('deal_unit_price') }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="dealVolume"
        contents={{ text: t('deal_volume') }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="recap"
        contents={{ text: t('recap') }}
      />
    </FlexTableRow>
  )
}

export default HistoryTableHeaderRow
