import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'
import { listOrdered } from '@enechain/etender-proto/proto/bff/v1/tender_service-TenderService_connectquery'
import { Position } from '@enechain/etender-proto/proto/etender/tender_terms/v1/model_pb'

import { TenderTermsColumnKey } from '~/pages/history/components/OrderedTenderSection/TenderTermsTable/TenderTermsColumnStyles'

type TenderTermsTableHeaderData = {
  allTenderTermsPosition: 'ask' | 'bid' | 'other'
}

const useTenderTermsTableHeaderData = (
  tenderId: string,
  pageSize: number,
  pageToken?: string,
): TenderTermsTableHeaderData | undefined => {
  const { data } = useSuspenseQuery(
    listOrdered,
    { pageSize, pageToken },
    {
      select: (response): TenderTermsTableHeaderData | undefined => {
        const tenderTermsIds =
          response?.tenderTermsIdMap[tenderId].tenderTermsIds
        if (tenderTermsIds.length <= 0) {
          return undefined
        }

        const positions = tenderTermsIds
          .map((tenderTermsId) => {
            return response?.dataSetForOrder?.tenderTerms[tenderTermsId]
              ?.position
          })
          .filter((position): position is Position => position != null)

        if (positions.length <= 0) {
          return {
            allTenderTermsPosition: 'other',
          }
        }

        if (positions.every((position) => position === Position.ASK)) {
          return {
            allTenderTermsPosition: 'ask',
          }
        }

        if (positions.every((position) => position === Position.BID)) {
          return {
            allTenderTermsPosition: 'bid',
          }
        }

        return {
          allTenderTermsPosition: 'other',
        }
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
  pageSize: number
  pageToken?: string
  withSwingOption: boolean
}

const TenderTermsTableHeader: React.FC<Props> = ({
  tenderId,
  pageSize,
  pageToken,
  withSwingOption,
}) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'history.tender_terms_table',
  })

  const data = useTenderTermsTableHeaderData(tenderId, pageSize, pageToken)

  const getPriceRestrictionLabel = (): string => {
    switch (data?.allTenderTermsPosition) {
      case 'ask':
        return t(withSwingOption ? 'min_unit_price_month' : 'min_unit_price')
      case 'bid':
        return t(withSwingOption ? 'max_unit_price_month' : 'max_unit_price')
      default:
        return t(
          withSwingOption ? 'price_restriction_month' : 'price_restriction',
        )
    }
  }

  if (data == null) {
    return null
  }

  return (
    <FlexTableRow>
      <FlexTableCell<TenderTermsColumnKey> columnKey="accordionSpace" />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="position"
        contents={{ text: t('bid_or_offer') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="area"
        contents={{ text: t('area') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="deliveryTerms"
        contents={{ text: t('delivery_terms') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="excludePeriods"
        contents={{ text: t('exclude_periods') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="hourType"
        contents={{ text: t('hour_type') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="productType"
        contents={{ text: t('product_type') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="priceRestriction"
        contents={{ text: getPriceRestrictionLabel() }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="maxVolume"
        contents={{ text: t('max_volume') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="minVolume"
        contents={{ text: t('min_volume') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="note"
        contents={{ text: t('note') }}
      />
    </FlexTableRow>
  )
}

export default React.memo(TenderTermsTableHeader)
