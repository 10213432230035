import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Accordion, AccordionItem, Flex, Text } from '@chakra-ui/react'

import { AccordionAllButton } from '@enechain/common-ui'
import {
  SemanticColors,
  Spacing,
  Typography,
  useAccordion,
} from '@enechain/ecloud-designsystem'
import { SleeveType } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

import OrderInformationTable from '~/components/order/OrderInformationTable'
import BidConfirmTable from '~/pages/order-confirm/components/OrderConfirmSection/BidConfirmTable'
import { useEditingOrder } from '~/pages/order-confirm/hooks/useEditingOrder'
import { useTenderById } from '~/pages/tender/hooks/useTenderList'

type OrderConfirmSectionData = {
  sleeveType: SleeveType
  tenderTermsIds: string[]
}

const useOrderConfirmSectionData = (
  orderId: string,
): OrderConfirmSectionData | undefined => {
  const { data } = useEditingOrder(orderId, (response) => {
    const contents = response.editingOrder?.contents
    if (contents == null) {
      return undefined
    }
    return {
      sleeveType: contents.sleeveType,
      tenderTermsIds: response.tenderTermsIds,
    }
  })
  return data
}

const useTenderSwingOption = (tenderId: string): boolean => {
  const { data } = useTenderById<boolean>(
    tenderId,
    (response) => response.tender.withSwingOption,
  )
  return data ?? false
}

type Props = {
  tenderId: string
  orderId: string
}

const OrderConfirmSection: React.FC<Props> = ({ tenderId, orderId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order_confirm',
  })

  const withSwingOption = useTenderSwingOption(tenderId)
  const data = useOrderConfirmSectionData(orderId)
  const tenderTermsIds = data?.tenderTermsIds ?? []

  const { onCollapseAll, onExpandAll, index, onChange, isAllCollapsed } =
    useAccordion(
      tenderTermsIds,
      tenderTermsIds.map((_, index) => index),
    )

  if (data == null || tenderTermsIds.length <= 0) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={Spacing['4']}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Flex flexDirection="column" gap={Spacing['1']}>
          <Text {...Typography.headerLg}>{t('order_label')}</Text>
          {(data.sleeveType === SleeveType.BILATERAL ||
            data.sleeveType === SleeveType.EEX ||
            data.sleeveType === SleeveType.TOCOM) && (
            <Text
              color={SemanticColors.Text.midEmphasis}
              {...Typography.textMd}
            >
              {t('order_sleeve_annotation')}
            </Text>
          )}
        </Flex>
        <AccordionAllButton
          isAllCollapsed={isAllCollapsed}
          onClickCollapseAll={onCollapseAll}
          onClickExpandAll={onExpandAll}
        />
      </Flex>
      <Accordion
        borderColor={SemanticColors.Border.midEmphasis}
        allowMultiple
        index={index}
        onChange={onChange}
      >
        <Flex flexDirection="column" gap={Spacing['10']}>
          {tenderTermsIds.map((tenderTermsId) => (
            <AccordionItem
              key={tenderTermsId}
              flexDirection="column"
              gap={Spacing['4']}
              display="flex"
              borderWidth={0}
              _last={{ borderBottomWidth: 0 }}
            >
              <OrderInformationTable
                orderId={orderId}
                tenderTermsId={tenderTermsId}
                withSwingOption={withSwingOption}
              >
                <BidConfirmTable
                  orderId={orderId}
                  tenderTermsId={tenderTermsId}
                  withSwingOption={withSwingOption}
                />
              </OrderInformationTable>
            </AccordionItem>
          ))}
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default React.memo(OrderConfirmSection)
