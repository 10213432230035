import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import {
  FlexTable,
  FlexTableBody,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
  SemanticColors,
} from '@enechain/ecloud-designsystem'

import EmptyBidRow from '~/components/order/EmptyBidRow'
import {
  BidColumnKey,
  BidColumnStyle,
} from '~/pages/order/components/OrderSection/BidTable/BidColumnStyle'
import BidTableBodyRow from '~/pages/order/components/OrderSection/BidTable/BidTableBodyRow'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'

type BidTableData = {
  bidIds: string[]
}

const useBidTableData = (
  orderId: string,
  tenderTermsId: string,
): BidTableData => {
  const { data } = useEditingOrder(orderId, (response) => {
    return {
      bidIds:
        response.editingOrder?.bids
          ?.filter((bid) => bid.tenderTermsId === tenderTermsId)
          .map((bid) => bid.id) ?? [],
    }
  })

  return data
}

type Props = {
  orderId: string
  tenderTermsId: string
  withSwingOption: boolean
}

const BidTable: React.FC<Props> = ({
  orderId,
  tenderTermsId,
  withSwingOption,
}) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'bid_table',
  })

  const { bidIds } = useBidTableData(orderId, tenderTermsId)

  return (
    <FlexTable<BidColumnKey> columnStyles={BidColumnStyle}>
      <FlexTableHeader style="sub-table">
        <FlexTableRow>
          <FlexTableCell<BidColumnKey> columnKey="accordionSpace" />
          <FlexTableCell<BidColumnKey>
            columnKey="bidId"
            contents={{ text: t('bid_id') }}
          />
          <FlexTableCell<BidColumnKey>
            columnKey="unitPrice"
            contents={{
              text: t(
                withSwingOption ? 'unit_price.label_month' : 'unit_price.label',
              ),
            }}
          />
          <FlexTableCell<BidColumnKey>
            columnKey="volume"
            contents={{ text: t('volume.label') }}
          />
          <FlexTableCell<BidColumnKey> columnKey="deleteBid" />
        </FlexTableRow>
      </FlexTableHeader>
      <FlexTableBody style="form">
        {bidIds.length > 0 ? (
          bidIds.map((bidId) => (
            <Box
              key={bidId}
              borderColor={SemanticColors.Border.lowEmphasis}
              borderBottomWidth="1px"
              _last={{ borderBottomWidth: 0 }}
            >
              <BidTableBodyRow
                bidId={bidId}
                orderId={orderId}
                tenderTermsId={tenderTermsId}
              />
            </Box>
          ))
        ) : (
          <EmptyBidRow />
        )}
      </FlexTableBody>
    </FlexTable>
  )
}

export default React.memo(BidTable)
