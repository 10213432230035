import * as React from 'react'

import { AccordionPanel } from '@chakra-ui/react'

import {
  BorderRadius,
  FlexTable,
  FlexTableBody,
  FlexTableHeader,
} from '@enechain/ecloud-designsystem'

import {
  OrderInformationColumnKey,
  orderInformationColumnStyle,
} from '~/components/order/OrderInformationTable/OrderInformationColumnStyle'
import OrderInformationTableBody from '~/components/order/OrderInformationTable/OrderInformationTableBodyRow'
import OrderInformationTableHeaderRow from '~/components/order/OrderInformationTable/OrderInformationTableHeaderRow'

type Props = {
  orderId: string
  tenderTermsId: string
  withSwingOption: boolean
  children: React.ReactNode
}

const OrderInformationTable: React.FC<Props> = ({
  orderId,
  tenderTermsId,
  children,
  withSwingOption,
}) => {
  return (
    <FlexTable<OrderInformationColumnKey>
      borderWidth="1px"
      borderRadius={BorderRadius.radiiLg}
      columnStyles={orderInformationColumnStyle}
    >
      <FlexTableHeader style="single-line">
        <OrderInformationTableHeaderRow
          orderId={orderId}
          tenderTermsId={tenderTermsId}
          withSwingOption={withSwingOption}
        />
      </FlexTableHeader>
      <FlexTableBody style="single-line">
        <OrderInformationTableBody
          orderId={orderId}
          tenderTermsId={tenderTermsId}
        />
        <AccordionPanel padding={0}>{children}</AccordionPanel>
      </FlexTableBody>
    </FlexTable>
  )
}

export default React.memo(OrderInformationTable)
