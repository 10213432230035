import * as React from 'react'

import {
  BorderRadius,
  FlexTable,
  FlexTableBody,
  FlexTableHeader,
} from '@enechain/ecloud-designsystem'

import {
  TenderTermsColumnKey,
  tenderTermsColumnStyle,
} from '~/pages/history-detail/components/OrderHistorySection/TenderTermsTable/TenderTermsColumnStyle'
import OrderInformationTableBodyRow from '~/pages/history-detail/components/OrderHistorySection/TenderTermsTable/TenderTermsTableBodyRow'
import OrderInformationTableHeaderRow from '~/pages/history-detail/components/OrderHistorySection/TenderTermsTable/TenderTermsTableHeaderRow'

type Props = {
  tenderId: string
  orderId?: string
  tenderTermsId: string
  children: React.ReactNode
  withSwingOption: boolean
}

const OrderInformationTable: React.FC<Props> = ({
  tenderId,
  orderId,
  tenderTermsId,
  children,
  withSwingOption,
}) => {
  return (
    <FlexTable<TenderTermsColumnKey>
      borderWidth="1px"
      borderRadius={BorderRadius.radiiLg}
      columnStyles={tenderTermsColumnStyle}
    >
      <FlexTableHeader style="single-line">
        <OrderInformationTableHeaderRow
          tenderId={tenderId}
          tenderTermsId={tenderTermsId}
          withSwingOption={withSwingOption}
        />
      </FlexTableHeader>
      <FlexTableBody style="single-line">
        <OrderInformationTableBodyRow
          orderId={orderId}
          tenderId={tenderId}
          tenderTermsId={tenderTermsId}
        />
        {children}
      </FlexTableBody>
    </FlexTable>
  )
}

export default React.memo(OrderInformationTable)
