import * as React from 'react'

import {
  FlexTable,
  FlexTableBody,
  FlexTableHeader,
} from '@enechain/ecloud-designsystem'

import EmptyBidRow from '~/components/order/EmptyBidRow'
import BidHistoryBodyBox from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/BidHistoryBodyBox'
import DealHistoryBodyBox from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/DealHistoryBodyBox'
import {
  HistoryColumnKey,
  HistoryColumnStyle,
} from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/HistoryColumnStyle'
import HistoryTableHeaderRow from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/HistoryTableHeaderRow'
import { useListMyDeals } from '~/pages/history-detail/components/OrderHistorySection/hooks/useListMyDeals'

type Props = {
  tenderId: string
  tenderTermsId: string
  orderId?: string
  displayType: 'bid' | 'sleever' | 'tender_owner'
  displayBidIDs?: string[]
  withSwingOption: boolean
}

const HistoryTable: React.FC<Props> = ({
  tenderId,
  orderId,
  tenderTermsId,
  displayType,
  displayBidIDs,
  withSwingOption,
}) => {
  const { deals } = useListMyDeals({
    tenderId,
  })

  const isSleeverOrTenderOwner =
    deals.length > 0 &&
    (deals[0].isCreditSleeveDeal || deals[0].isTenderOwnerDeal)

  return (
    <FlexTable<HistoryColumnKey> columnStyles={HistoryColumnStyle}>
      <FlexTableHeader style="sub-table">
        <HistoryTableHeaderRow
          isSleeverOrTenderOwner={isSleeverOrTenderOwner}
          withSwingOption={withSwingOption}
        />
      </FlexTableHeader>
      <FlexTableBody style="sub-table">
        {deals.length > 0 ? (
          <DealHistoryBodyBox
            displayBidIDs={displayBidIDs}
            displayType={displayType}
            tenderId={tenderId}
            tenderTermsId={tenderTermsId}
          />
        ) : orderId != null ? (
          <BidHistoryBodyBox
            orderId={orderId}
            tenderId={tenderId}
            tenderTermsId={tenderTermsId}
          />
        ) : (
          <EmptyBidRow />
        )}
      </FlexTableBody>
    </FlexTable>
  )
}

export default React.memo(HistoryTable)
