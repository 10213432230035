import * as React from 'react'

import { Button } from '@chakra-ui/react'

import { RecapStatus } from '@enechain/common-ui'
import DealStatusLabel from '@enechain/common-ui/src/components/DealStatusLabel'
import PositionLabel from '@enechain/common-ui/src/components/PositionLabel'
import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'
import {
  DealStatus,
  MyDeal,
  Position,
} from '@enechain/etender-proto/proto/etender/deal/v1/model_pb'

import EmptyBidRow from '~/components/order/EmptyBidRow'
import { HistoryColumnKey } from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/HistoryColumnStyle'
import { useListMyDeals } from '~/pages/history-detail/components/OrderHistorySection/hooks/useListMyDeals'

type Props = {
  tenderId: string
  tenderTermsId: string
  displayType: 'bid' | 'sleever' | 'tender_owner'
  displayBidIDs?: string[]
}

const DealHistoryBodyRow: React.FC<Props> = ({
  tenderId,
  tenderTermsId,
  displayType,
  displayBidIDs,
}) => {
  const { deals } = useListMyDeals({
    tenderId,
    tenderTermsId,
    filter: displayType,
  })

  if (deals.length === 0) {
    return <EmptyBidRow />
  }

  const dealStatus = (
    status: DealStatus | undefined,
  ): 'filled' | 'partially_filled' | 'unfilled' => {
    switch (status) {
      case DealStatus.FILLED:
        return 'filled'
      case DealStatus.PARTIALLY_FILLED:
        return 'partially_filled'
      case DealStatus.UNFILLED:
        return 'unfilled'
      default:
        return 'unfilled'
    }
  }

  const position = (position: Position): 'bid' | 'offer' | undefined => {
    switch (position) {
      case Position.BID:
        return 'bid'
      case Position.ASK:
        return 'offer'
      default:
        return undefined
    }
  }
  const displayPosition = (deal: MyDeal): 'bid' | 'offer' => {
    const p = position(deal.position)
    if (p === undefined) {
      throw new Error('Invalid position')
    }

    if (deal.isCreditSleeveDeal && deal.isCreditSleeveSelfSource) {
      // スリーバー側(自社がsource)の場合は逆のポジションを表示
      return p === 'bid' ? 'offer' : 'bid'
    }
    return p
  }

  const displayOrganizationName = (deal: MyDeal): string => {
    if (deal.deal?.dealStatus === DealStatus.UNFILLED) {
      return '-' // 不成立は表示なしで統一する
    }
    return deal.dealOrganizationName ?? '非公開'
  }

  const onClickShowRecap = (id?: string): void => {
    if (id === undefined) {
      return
    }
    window.open(`${import.meta.env.VITE_RECAP_USER_URL}/recap/${id}`, '_blank')
  }

  return deals
    .filter(({ deal }) =>
      displayBidIDs != null && deal?.bidId != null
        ? displayBidIDs.includes(deal.bidId)
        : true,
    )
    .map((deal) => (
      <FlexTableRow key={deal.deal?.id}>
        <FlexTableCell<HistoryColumnKey> columnKey="bid_or_offer">
          <PositionLabel position={displayPosition(deal)} />
        </FlexTableCell>
        <FlexTableCell<HistoryColumnKey>
          columnKey="bidId"
          contents={{ text: deal.deal?.bidId ?? '-' }}
        />
        {!deal.isCreditSleeveDeal && !deal.isTenderOwnerDeal && (
          <>
            <FlexTableCell<HistoryColumnKey>
              columnKey="unitPrice"
              contents={{
                text: deal.originalUnitPrice ?? deal.deal?.unitPrice ?? '',
              }}
            />
            <FlexTableCell<HistoryColumnKey>
              columnKey="volume"
              contents={{
                text: deal.originalVolume ?? deal.deal?.volume ?? '',
              }}
            />
          </>
        )}
        <FlexTableCell<HistoryColumnKey> columnKey="dealStatus">
          <DealStatusLabel status={dealStatus(deal.deal?.dealStatus)} />
        </FlexTableCell>
        <FlexTableCell<HistoryColumnKey>
          columnKey="dealOrganizationName"
          contents={{ text: displayOrganizationName(deal) }}
        />
        <FlexTableCell<HistoryColumnKey>
          columnKey="dealUnitPrice"
          contents={{
            text:
              deal.deal?.dealStatus !== DealStatus.UNFILLED
                ? (deal.deal?.unitPrice ?? '-')
                : '-',
          }}
        />
        <FlexTableCell<HistoryColumnKey>
          columnKey="dealVolume"
          contents={{
            text:
              deal.deal?.dealStatus !== DealStatus.UNFILLED
                ? (deal.deal?.volume ?? '-')
                : '-',
          }}
        />
        <FlexTableCell<HistoryColumnKey> columnKey="recap">
          {deal.publishedRecap ? (
            <Button
              backgroundColor="transparent"
              onClick={(): void => onClickShowRecap(deal.recapId)}
              paddingInline={0}
            >
              <RecapStatus published />
            </Button>
          ) : (
            <RecapStatus published={false} />
          )}
        </FlexTableCell>
      </FlexTableRow>
    ))
}

export default React.memo(DealHistoryBodyRow)
