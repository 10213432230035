import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'
import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'
import { Position } from '@enechain/etender-proto/proto/etender/tender_terms/v1/model_pb'

import { TenderTermsColumnKey } from '~/pages/history-detail/components/OrderHistorySection/TenderTermsTable/TenderTermsColumnStyle'

type OrderInformationTableHeaderData = {
  position: Position
}

const useOrderInformationTableHeaderData = (
  tenderId: string,
  tenderTermsId: string,
): OrderInformationTableHeaderData | undefined => {
  const { data } = useSuspenseQuery(
    listByTenderID,
    {
      tenderId,
    },
    {
      select: (response): OrderInformationTableHeaderData | undefined => {
        const terms = response.dataSetForOrder?.tenderTerms[tenderTermsId]
        if (terms == null) {
          return undefined
        }
        return {
          position: terms.position,
        }
      },
    },
  )

  return data
}

type Props = {
  tenderId: string
  tenderTermsId: string
  withSwingOption: boolean
}

const TenderTermsTableHeaderRow: React.FC<Props> = ({
  tenderId,
  tenderTermsId,
  withSwingOption,
}) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'history_detail.order_history_section.tender_terms_table',
  })

  const data = useOrderInformationTableHeaderData(tenderId, tenderTermsId)

  const getPriceRestrictionLabel = (): string => {
    switch (data?.position) {
      case Position.ASK:
        return t(withSwingOption ? 'min_unit_price_month' : 'min_unit_price')
      case Position.BID:
        return t(withSwingOption ? 'max_unit_price_month' : 'max_unit_price')
      default:
        return t('price_restriction')
    }
  }

  return (
    <FlexTableRow>
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="position"
        contents={{ text: t('bid_or_offer') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="area"
        contents={{ text: t('area') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="deliveryTerms"
        contents={{ text: t('delivery_terms') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="excludePeriods"
        contents={{ text: t('exclude_periods') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="hourType"
        contents={{ text: t('hour_type') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="productType"
        contents={{ text: t('product_type') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="priceRestriction"
        contents={{ text: getPriceRestrictionLabel() }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="maxVolume"
        contents={{ text: t('max_volume') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="minVolume"
        contents={{ text: t('min_volume') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="note"
        contents={{ text: t('note') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="bidCount"
        contents={{ text: t('bid_count.label') }}
      />
    </FlexTableRow>
  )
}

export default React.memo(TenderTermsTableHeaderRow)
