// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=js+dts"
// @generated from file etender/tender/v1/service.proto (package etender.tender.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { BatchListRequest, BatchListResponse, CreateEmptyRequest, CreateEmptyResponse, DeleteAttachmentRequest, DeleteAttachmentResponse, DeleteRequest, DeleteResponse, GetAttachmentURLRequest, GetAttachmentURLResponse, GetEditableAttachmentURLRequest, GetEditableAttachmentURLResponse, GetEditableRequest, GetEditableResponse, GetRequest, GetResponse, ListEditableRequest, ListEditableResponse, ListOrderedRequest, ListOrderedResponse, ListRequest, ListResponse, UpdateAccessControlRequest, UpdateAccessControlResponse, UpdateEnabledRequest, UpdateEnabledResponse, UpdateOperatorsRequest, UpdateOperatorsResponse, UpdateOwnerInfoRequest, UpdateOwnerInfoResponse, UpdatePropertiesRequest, UpdatePropertiesResponse, UpdateScheduleRequest, UpdateScheduleResponse, UpdateSleeversRequest, UpdateSleeversResponse, UpdateTermsSettingRequest, UpdateTermsSettingResponse, VerifyBiddableRequest, VerifyBiddableResponse } from "./service_pb.js";

/**
 * 参加可能な入札案件の一覧を取得する
 *
 * @generated from rpc etender.tender.v1.Service.List
 */
export const list = {
  localName: "list",
  name: "List",
  kind: MethodKind.Unary,
  I: ListRequest,
  O: ListResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 複数の入札案件を取得する
 *
 * @generated from rpc etender.tender.v1.Service.BatchList
 */
export const batchList = {
  localName: "batchList",
  name: "BatchList",
  kind: MethodKind.Unary,
  I: BatchListRequest,
  O: BatchListResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 注文済みの入札案件の一覧を取得する
 *
 * @generated from rpc etender.tender.v1.Service.ListOrdered
 */
export const listOrdered = {
  localName: "listOrdered",
  name: "ListOrdered",
  kind: MethodKind.Unary,
  I: ListOrderedRequest,
  O: ListOrderedResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 編集可能な入札案件の一覧を取得する
 *
 * @generated from rpc etender.tender.v1.Service.ListEditable
 */
export const listEditable = {
  localName: "listEditable",
  name: "ListEditable",
  kind: MethodKind.Unary,
  I: ListEditableRequest,
  O: ListEditableResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 参加可能な入札案件を取得する
 *
 * @generated from rpc etender.tender.v1.Service.Get
 */
export const get = {
  localName: "get",
  name: "Get",
  kind: MethodKind.Unary,
  I: GetRequest,
  O: GetResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 編集可能な入札案件を取得する
 *
 * @generated from rpc etender.tender.v1.Service.GetEditable
 */
export const getEditable = {
  localName: "getEditable",
  name: "GetEditable",
  kind: MethodKind.Unary,
  I: GetEditableRequest,
  O: GetEditableResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 参加可能な入札案件の添付ファイルの署名付きURLを取得する
 *
 * @generated from rpc etender.tender.v1.Service.GetAttachmentURL
 */
export const getAttachmentURL = {
  localName: "getAttachmentURL",
  name: "GetAttachmentURL",
  kind: MethodKind.Unary,
  I: GetAttachmentURLRequest,
  O: GetAttachmentURLResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 編集可能な入札案件の添付ファイルの署名付きURLを取得する
 *
 * @generated from rpc etender.tender.v1.Service.GetEditableAttachmentURL
 */
export const getEditableAttachmentURL = {
  localName: "getEditableAttachmentURL",
  name: "GetEditableAttachmentURL",
  kind: MethodKind.Unary,
  I: GetEditableAttachmentURLRequest,
  O: GetEditableAttachmentURLResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 応札可能かどうかを確認する
 *
 * @generated from rpc etender.tender.v1.Service.VerifyBiddable
 */
export const verifyBiddable = {
  localName: "verifyBiddable",
  name: "VerifyBiddable",
  kind: MethodKind.Unary,
  I: VerifyBiddableRequest,
  O: VerifyBiddableResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 空の入札案件を作成する
 *
 * @generated from rpc etender.tender.v1.Service.CreateEmpty
 */
export const createEmpty = {
  localName: "createEmpty",
  name: "CreateEmpty",
  kind: MethodKind.Unary,
  I: CreateEmptyRequest,
  O: CreateEmptyResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 入札案件のプロパティを更新する
 *
 * @generated from rpc etender.tender.v1.Service.UpdateProperties
 */
export const updateProperties = {
  localName: "updateProperties",
  name: "UpdateProperties",
  kind: MethodKind.Unary,
  I: UpdatePropertiesRequest,
  O: UpdatePropertiesResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 入札案件の開催主の情報を更新する
 *
 * @generated from rpc etender.tender.v1.Service.UpdateOwnerInfo
 */
export const updateOwnerInfo = {
  localName: "updateOwnerInfo",
  name: "UpdateOwnerInfo",
  kind: MethodKind.Unary,
  I: UpdateOwnerInfoRequest,
  O: UpdateOwnerInfoResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 入札案件のスケジュールを更新する
 *
 * @generated from rpc etender.tender.v1.Service.UpdateSchedule
 */
export const updateSchedule = {
  localName: "updateSchedule",
  name: "UpdateSchedule",
  kind: MethodKind.Unary,
  I: UpdateScheduleRequest,
  O: UpdateScheduleResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 入札案件のアクセス制御を更新する
 *
 * @generated from rpc etender.tender.v1.Service.UpdateAccessControl
 */
export const updateAccessControl = {
  localName: "updateAccessControl",
  name: "UpdateAccessControl",
  kind: MethodKind.Unary,
  I: UpdateAccessControlRequest,
  O: UpdateAccessControlResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 入札案件のオペレーターを更新する
 *
 * @generated from rpc etender.tender.v1.Service.UpdateOperators
 */
export const updateOperators = {
  localName: "updateOperators",
  name: "UpdateOperators",
  kind: MethodKind.Unary,
  I: UpdateOperatorsRequest,
  O: UpdateOperatorsResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 入札案件のスリーバーを更新する
 *
 * @generated from rpc etender.tender.v1.Service.UpdateSleevers
 */
export const updateSleevers = {
  localName: "updateSleevers",
  name: "UpdateSleevers",
  kind: MethodKind.Unary,
  I: UpdateSleeversRequest,
  O: UpdateSleeversResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 入札案件の有効かどうかを更新する
 *
 * @generated from rpc etender.tender.v1.Service.UpdateEnabled
 */
export const updateEnabled = {
  localName: "updateEnabled",
  name: "UpdateEnabled",
  kind: MethodKind.Unary,
  I: UpdateEnabledRequest,
  O: UpdateEnabledResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 入札案件の商品設定を更新する
 *
 * @generated from rpc etender.tender.v1.Service.UpdateTermsSetting
 */
export const updateTermsSetting = {
  localName: "updateTermsSetting",
  name: "UpdateTermsSetting",
  kind: MethodKind.Unary,
  I: UpdateTermsSettingRequest,
  O: UpdateTermsSettingResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 入札案件を削除する
 *
 * @generated from rpc etender.tender.v1.Service.Delete
 */
export const delete$ = {
  localName: "delete",
  name: "Delete",
  kind: MethodKind.Unary,
  I: DeleteRequest,
  O: DeleteResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};

/**
 * 添付ファイルを削除する
 *
 * @generated from rpc etender.tender.v1.Service.DeleteAttachment
 */
export const deleteAttachment = {
  localName: "deleteAttachment",
  name: "DeleteAttachment",
  kind: MethodKind.Unary,
  I: DeleteAttachmentRequest,
  O: DeleteAttachmentResponse,
  service: {
    typeName: "etender.tender.v1.Service"
  }
};
