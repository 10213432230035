// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/deal/v1/model.proto (package etender.deal.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum etender.deal.v1.Position
 */
export const Position = /*@__PURE__*/ proto3.makeEnum(
  "etender.deal.v1.Position",
  [
    {no: 0, name: "POSITION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "POSITION_ASK", localName: "ASK"},
    {no: 2, name: "POSITION_BID", localName: "BID"},
  ],
);

/**
 * @generated from enum etender.deal.v1.DealStatus
 */
export const DealStatus = /*@__PURE__*/ proto3.makeEnum(
  "etender.deal.v1.DealStatus",
  [
    {no: 0, name: "DEAL_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DEAL_STATUS_FILLED", localName: "FILLED"},
    {no: 2, name: "DEAL_STATUS_PARTIALLY_FILLED", localName: "PARTIALLY_FILLED"},
    {no: 3, name: "DEAL_STATUS_UNFILLED", localName: "UNFILLED"},
  ],
);

/**
 * @generated from enum etender.deal.v1.ClearingType
 */
export const ClearingType = /*@__PURE__*/ proto3.makeEnum(
  "etender.deal.v1.ClearingType",
  [
    {no: 0, name: "CLEARING_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CLEARING_TYPE_EEX", localName: "EEX"},
    {no: 2, name: "CLEARING_TYPE_TOCOM", localName: "TOCOM"},
    {no: 3, name: "CLEARING_TYPE_ECLEAR", localName: "ECLEAR"},
    {no: 4, name: "CLEARING_TYPE_DIRECT", localName: "DIRECT"},
  ],
);

/**
 * @generated from enum etender.deal.v1.CalendarType
 */
export const CalendarType = /*@__PURE__*/ proto3.makeEnum(
  "etender.deal.v1.CalendarType",
  [
    {no: 0, name: "CALENDAR_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CALENDAR_TYPE_TOKYO", localName: "TOKYO"},
    {no: 2, name: "CALENDAR_TYPE_HOKKAIDO", localName: "HOKKAIDO"},
    {no: 3, name: "CALENDAR_TYPE_CHUBU", localName: "CHUBU"},
    {no: 4, name: "CALENDAR_TYPE_TOHOKU", localName: "TOHOKU"},
    {no: 5, name: "CALENDAR_TYPE_KYUSHU", localName: "KYUSHU"},
    {no: 6, name: "CALENDAR_TYPE_CHUGOKU", localName: "CHUGOKU"},
    {no: 7, name: "CALENDAR_TYPE_HOKURIKU", localName: "HOKURIKU"},
    {no: 8, name: "CALENDAR_TYPE_KANSAI", localName: "KANSAI"},
    {no: 9, name: "CALENDAR_TYPE_SHIKOKU", localName: "SHIKOKU"},
    {no: 10, name: "CALENDAR_TYPE_TOCOM", localName: "TOCOM"},
    {no: 11, name: "CALENDAR_TYPE_EEX", localName: "EEX"},
  ],
);

/**
 * @generated from enum etender.deal.v1.DealPublishStatus
 */
export const DealPublishStatus = /*@__PURE__*/ proto3.makeEnum(
  "etender.deal.v1.DealPublishStatus",
  [
    {no: 0, name: "DEAL_PUBLISH_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DEAL_PUBLISH_STATUS_PUBLISHED", localName: "PUBLISHED"},
    {no: 2, name: "DEAL_PUBLISH_STATUS_UNPUBLISHED", localName: "UNPUBLISHED"},
  ],
);

/**
 * @generated from message etender.deal.v1.TenderDeal
 */
export const TenderDeal = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.TenderDeal",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(DealPublishStatus) },
    { no: 4, name: "deal", kind: "message", T: Deal, repeated: true },
    { no: 5, name: "published_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message etender.deal.v1.Deal
 */
export const Deal = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.Deal",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bid_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "deal_source_organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "deal_destination_organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "deal_status", kind: "enum", T: proto3.getEnumType(DealStatus) },
    { no: 6, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "recap_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "published_recap", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "clearing_type", kind: "enum", T: proto3.getEnumType(ClearingType) },
    { no: 11, name: "calendar_type", kind: "enum", T: proto3.getEnumType(CalendarType) },
    { no: 12, name: "total_contract_volume", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message etender.deal.v1.MyDeal
 */
export const MyDeal = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.MyDeal",
  () => [
    { no: 1, name: "deal", kind: "message", T: Deal },
    { no: 2, name: "tender_terms_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "original_unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "original_volume", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "deal_organization_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "is_credit_sleeve_deal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_tender_owner_deal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "position", kind: "enum", T: proto3.getEnumType(Position) },
    { no: 10, name: "recap_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "published_recap", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "is_credit_sleeve_self_source", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message etender.deal.v1.RecapAnnex
 */
export const RecapAnnex = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.RecapAnnex",
  () => [
    { no: 1, name: "conditions", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "payment_due_date", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "brokerage_fee", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "destination_brokerage_fee", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message etender.deal.v1.RecapDeal
 */
export const RecapDeal = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.RecapDeal",
  () => [
    { no: 1, name: "deal_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "deal_source_organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "deal_destination_organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "deal_source_user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "deal_destination_user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "deal_source_phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "deal_destination_phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "deal_source_mail_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "deal_destination_mail_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "product", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "delivery_period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "total_contract_volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "clearing_type", kind: "enum", T: proto3.getEnumType(ClearingType) },
    { no: 17, name: "position", kind: "enum", T: proto3.getEnumType(Position) },
    { no: 18, name: "with_swing_option", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

