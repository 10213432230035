import * as React from 'react'
import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { Await, useLoaderData, useNavigate } from 'react-router-dom'

import { Flex, useDisclosure } from '@chakra-ui/react'

import { ErrorLayout } from '@enechain/common-ui'
import {
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { SideMenuLayoutBreadcrumbItem } from '@enechain/ecloud-designsystem/dist/src/components/SideMenuLayout/components/SideMenuLayoutBreadcrumb'
import { Status } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

import AdditionalFieldsConfirmSection from '~/pages/order-confirm/components/AdditionalFieldsConfirmSection'
import OrderConfirmFooter from '~/pages/order-confirm/components/Footer'
import OrderConfirmHeader from '~/pages/order-confirm/components/Header'
import OrderCompleteModal from '~/pages/order-confirm/components/OrderCompleteModal'
import OrderConfirmSection from '~/pages/order-confirm/components/OrderConfirmSection'
import OrderEditedModal from '~/pages/order-confirm/components/OrderEditedModal'
import {
  OrderConfirmLoaderData,
  OrderConfirmUrlParams,
} from '~/pages/order-confirm/loader'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'
import { routePaths } from '~/routes/routePaths'

type OrderConfirmPageData = {
  orderType: 'order' | 'edit'
}

const useOrderConfirmPageData = (orderId: string): OrderConfirmPageData => {
  const { data } = useEditingOrder<OrderConfirmPageData>(
    orderId,
    (response) => {
      const isDraft = response.editingOrder?.status === Status.DRAFT

      return {
        orderType: isDraft ? 'order' : 'edit',
      }
    },
  )
  return data
}

const Layout: React.FC<{ urlParams: OrderConfirmUrlParams }> = ({
  urlParams,
}) => {
  const { tenderId, orderId } = urlParams
  const navigate = useNavigate()
  const { t } = useTranslation('trader', {
    keyPrefix: 'order_confirm',
  })

  const { orderType } = useOrderConfirmPageData(orderId)

  const completeDisclosure = useDisclosure()
  const editedDisclosure = useDisclosure()

  const breadcrumbItems = useMemo<SideMenuLayoutBreadcrumbItem[]>(() => {
    switch (orderType) {
      case 'order':
        return [
          {
            label: t('breadcrumb.order'),
            onClick: (): void =>
              navigate(routePaths.order({ params: { tenderId, orderId } })),
          },
          { label: t('page_title'), bold: true },
        ]
      case 'edit':
        return [
          {
            label: t('breadcrumb.history'),
            onClick: (): void => navigate(routePaths.history()),
          },
          {
            label: t('breadcrumb.history_detail'),
            onClick: (): void =>
              navigate(
                routePaths.historyDetail({
                  params: { tenderId },
                }),
              ),
          },
          {
            label: t('breadcrumb.order'),
            onClick: (): void =>
              navigate(routePaths.order({ params: { tenderId, orderId } })),
          },
          { label: t('page_title'), bold: true },
        ]
    }
  }, [navigate, orderId, orderType, t, tenderId])

  return (
    <SideMenuLayoutContentLayout
      header={<SideMenuLayoutBreadcrumb items={breadcrumbItems} />}
    >
      <>
        <OrderConfirmHeader orderId={orderId} />
        <Flex flexDirection="column" width="100%" height="100%">
          <Flex
            flexDirection="column"
            flexGrow={1}
            gap={Spacing['8']}
            overflow="scroll"
            width="100%"
            padding={Spacing['6']}
          >
            <OrderConfirmSection orderId={orderId} tenderId={tenderId} />
            <AdditionalFieldsConfirmSection orderId={orderId} />
          </Flex>
          <OrderConfirmFooter
            onOpenOrderCompleteModal={completeDisclosure.onOpen}
            onOpenOrderEditModal={editedDisclosure.onOpen}
            orderId={orderId}
            tenderId={tenderId}
          />
        </Flex>
        <OrderCompleteModal disclosure={completeDisclosure} />;
        <OrderEditedModal disclosure={editedDisclosure} tenderId={tenderId} />;
      </>
    </SideMenuLayoutContentLayout>
  )
}

const OrderConfirmPage: React.FC = () => {
  const { urlParams } = useLoaderData() as OrderConfirmLoaderData

  return (
    <Await errorElement={<ErrorLayout />} resolve={urlParams}>
      {(urlParams: OrderConfirmUrlParams): JSX.Element => (
        <Layout urlParams={urlParams} />
      )}
    </Await>
  )
}

export default OrderConfirmPage
