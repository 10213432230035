// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/tender/v1/model.proto (package etender.tender.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * *
 * 入札条件のステータス
 *
 * @generated from enum etender.tender.v1.TenderStatus
 */
export const TenderStatus = /*@__PURE__*/ proto3.makeEnum(
  "etender.tender.v1.TenderStatus",
  [
    {no: 0, name: "TENDER_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TENDER_STATUS_UNPUBLISHED", localName: "UNPUBLISHED"},
    {no: 2, name: "TENDER_STATUS_PUBLISHING", localName: "PUBLISHING"},
    {no: 3, name: "TENDER_STATUS_ARCHIVED", localName: "ARCHIVED"},
    {no: 4, name: "TENDER_STATUS_DISABLED", localName: "DISABLED"},
  ],
);

/**
 * *
 * 入札案件の種別
 *
 * @generated from enum etender.tender.v1.Commodity
 */
export const Commodity = /*@__PURE__*/ proto3.makeEnum(
  "etender.tender.v1.Commodity",
  [
    {no: 0, name: "COMMODITY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COMMODITY_POWER", localName: "POWER"},
  ],
);

/**
 * *
 * 入札案件のアクセス制御の種別
 *
 * @generated from enum etender.tender.v1.AccessControlType
 */
export const AccessControlType = /*@__PURE__*/ proto3.makeEnum(
  "etender.tender.v1.AccessControlType",
  [
    {no: 0, name: "ACCESS_CONTROL_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACCESS_CONTROL_TYPE_NONE", localName: "NONE"},
    {no: 2, name: "ACCESS_CONTROL_TYPE_BLACKLIST", localName: "BLACKLIST"},
    {no: 3, name: "ACCESS_CONTROL_TYPE_WHITELIST", localName: "WHITELIST"},
  ],
);

/**
 * *
 * 入札案件
 *
 * @generated from message etender.tender.v1.Tender
 */
export const Tender = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.Tender",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "commodity", kind: "enum", T: proto3.getEnumType(Commodity) },
    { no: 3, name: "properties", kind: "message", T: Properties },
    { no: 4, name: "owner_organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "schedule", kind: "message", T: Schedule },
    { no: 6, name: "attachments", kind: "message", T: Attachment, repeated: true },
    { no: 7, name: "bid_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "sleevers_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "with_swing_option", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * *
 * 編集可能な入札案件
 *
 * @generated from message etender.tender.v1.EditableTender
 */
export const EditableTender = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.EditableTender",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "commodity", kind: "enum", T: proto3.getEnumType(Commodity) },
    { no: 3, name: "properties", kind: "message", T: Properties },
    { no: 4, name: "owner", kind: "message", T: OwnerInfo },
    { no: 5, name: "schedule", kind: "message", T: Schedule },
    { no: 6, name: "access_control", kind: "message", T: AccessControl },
    { no: 7, name: "operators", kind: "message", T: Operators },
    { no: 8, name: "attachments", kind: "message", T: Attachment, repeated: true },
    { no: 9, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "sleevers", kind: "message", T: Sleevers },
    { no: 11, name: "with_swing_option", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * *
 * 入札案件のプロパティ
 *
 * @generated from message etender.tender.v1.Properties
 */
export const Properties = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.Properties",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * 開催主の情報
 *
 * @generated from message etender.tender.v1.OwnerInfo
 */
export const OwnerInfo = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.OwnerInfo",
  () => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contact_trader_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * *
 * 入札案件のスケジュール
 *
 * @generated from message etender.tender.v1.Schedule
 */
export const Schedule = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.Schedule",
  () => [
    { no: 1, name: "publish_at", kind: "message", T: Timestamp },
    { no: 2, name: "open_at", kind: "message", T: Timestamp },
    { no: 3, name: "ultimate_buyer_close_at", kind: "message", T: Timestamp },
    { no: 4, name: "close_at", kind: "message", T: Timestamp },
    { no: 5, name: "award_announce_at", kind: "message", T: Timestamp },
    { no: 6, name: "archive_at", kind: "message", T: Timestamp },
  ],
);

/**
 * *
 * 入札案件のアクセス制御
 *
 * @generated from message etender.tender.v1.AccessControl
 */
export const AccessControl = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.AccessControl",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(AccessControlType) },
    { no: 2, name: "organization_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * *
 * 入札案件のオペレーター
 *
 * @generated from message etender.tender.v1.Operators
 */
export const Operators = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.Operators",
  () => [
    { no: 1, name: "user_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * *
 * 入札案件の添付ファイル
 *
 * @generated from message etender.tender.v1.Attachment
 */
export const Attachment = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.Attachment",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * スリーバーとして参加する組織情報
 *
 * @generated from message etender.tender.v1.Sleevers
 */
export const Sleevers = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.Sleevers",
  () => [
    { no: 1, name: "sleever_organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

